type MapObjectToOptionProps<T> = {
  object: T;
  value: keyof T;
  label: keyof T | ((item: T) => string);
  disabled?: (item: T) => boolean;
};

type MapDataToOptionsProps<T> = Omit<MapObjectToOptionProps<T>, "object"> & {
  data: T[];
};

export const mapObjectToOption = <T>({
  object,
  value,
  label,
  disabled,
}: MapObjectToOptionProps<T>) => {
  return {
    value: `${object[value]}`,
    label: typeof label === "function" ? label(object) : `${object[label]}`,
    disabled: disabled ? disabled(object) : false,
  };
};

export const mapDataToOptions = <T>({
  data,
  value,
  label,
  disabled,
}: MapDataToOptionsProps<T>) => {
  return data.map((item) => {
    return {
      value: `${item[value]}`,
      label: typeof label === "function" ? label(item) : `${item[label]}`,
      disabled: disabled ? disabled(item) : false,
    };
  });
};
