<template>
  <div class="FormInput__container">
    <o-field
      class="FormDropdown__wrapper"
      :class="[{ icon }, { filled: !value }]"
    >
      <template #label>{{ label }}</template>
      <o-dropdown
        v-model="value"
        :scrollable="true"
        :max-height="170"
        aria-role="list"
        class="dropdown"
        :mobile-modal="false"
        :disabled="disabled"
        @focus.once="handleFocus(true)"
        @blur.once="handleFocus(false)"
        @update:model-value="emitUpdateModelValue"
      >
        <template #trigger="{ active }">
          <o-button :variant="variant" type="button">
            <o-icon v-if="icon" class="icon-input" :icon="icon"></o-icon>
            <span class="o-btn__label--text">{{ computedLabel }}</span>
            <o-icon
              class="icon-arrow"
              :icon="active ? 'chevron-up' : 'chevron-down'"
            ></o-icon>
          </o-button>
        </template>

        <o-dropdown-item
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          aria-role="listitem"
        >
          <div>
            <div>
              <span>{{ option.label }}</span>
            </div>
          </div>
        </o-dropdown-item>
      </o-dropdown>
    </o-field>
    <span class="FormInput__error">
      {{ !focused && !meta.valid ? errorMessage : "" }}
    </span>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import { useField } from "vee-validate";
import { Option } from "./types";

type Value = string | number;

type Props = {
  icon?: string;
  label: string;
  name: string;
  options: Option[];
  disabled?: boolean;
  initialValue?: Value;
};
const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue"]);
const emitUpdateModelValue = (value: Value) => {
  emit("update:modelValue", value);
};

const focused = ref(false);

const { value, errorMessage, meta } = useField(() => props.name, undefined, {
  initialValue: props.initialValue,
});

const handleFocus = (value: boolean) => {
  focused.value = value;
};

const computedLabel = computed(() => {
  const label = props.options.find((item) => item.value === value.value)?.label;
  return label || "";
});

const variant = computed(() => {
  if (meta.dirty && !focused.value && !meta.valid) {
    return "danger";
  }
  if (meta.dirty && meta.valid) {
    return "success";
  }
  return "primary";
});

defineExpose({ value });
</script>
<style scoped lang="scss">
.FormInput {
  &__container {
    display: flex;
    flex-direction: column;
  }
  &__error {
    @include body-s;
    color: $color-basic-error;
    margin: 4px 0;
    &.light {
      color: $color-basic-error-light;
    }
  }
}
</style>
